import { CerbosValidationRequest } from './cerbos.interface';

export enum CerbosRole {
	GCM = 'Global Commodity Manager',
	SCP = 'Supply Chain Professional',
	Engineer = 'Engineer', // not in matrix (empty column)
	MCET = 'MCET',
	BusinessAdmin = 'Business Admin',
	SystemAdmin = 'System Admin',
	BU = 'BU'
}

export const RoleNamesMap = {
	[CerbosRole.GCM]: 'Commodity Manager',
	[CerbosRole.SCP]: 'Supply Chain Professional',
	[CerbosRole.Engineer]: 'Engineer',
	[CerbosRole.MCET]: 'MCET',
	[CerbosRole.BusinessAdmin]: 'Business Admin',
	[CerbosRole.SystemAdmin]: 'System Admin',
	[CerbosRole.BU]: 'BU'
} as Record<string, string>;

export enum CerbosEffect {
	Allow = 'EFFECT_ALLOW',
	Deny = 'EFFECT_DENY'
}

export enum CerbosResource {
	Quote = 'Quote',
	AdminPanel = 'AdminPanel'
}

export enum QuoteActions {
	Create = `${CerbosResource.Quote}:Create`,
	View = `${CerbosResource.Quote}:View`,
	DeleteWhenScaRun = `${CerbosResource.Quote}:Delete When No SCA wasn't Run`,
	DeleteWhenNoSca = `${CerbosResource.Quote}:Delete When No SCA was Run`,
	Close = `${CerbosResource.Quote}:Close`,
	Edit = `${CerbosResource.Quote}:Edit`,
	Search = `${CerbosResource.Quote}:Search`,

	AssignRegion = `${CerbosResource.Quote}:Assign Region`,
	AssignQuantity = `${CerbosResource.Quote}:Assign Quantity`,

	// invitations
	InviteOwners = `${CerbosResource.Quote}:Invite Owners`,
	InviteContributors = `${CerbosResource.Quote}:Invite Contributors`,

	// Part Actions
	AddPart = `${CerbosResource.Quote}:Add Part`,
	DeletePart = `${CerbosResource.Quote}:Delete Part`,
	SaveAndRunSca = `${CerbosResource.Quote}:Save & Run SCA`,
	ReRunSca = `${CerbosResource.Quote}:Re-Run SCA`,
	ViewPart = `${CerbosResource.Quote}:View Part`,
	ConfigurePart = `${CerbosResource.Quote}:Configure Part`,
	RequestAprioriEstimate = `${CerbosResource.Quote}:Request aPriori Estimate`,
	VerifyEstimate = `${CerbosResource.Quote}:Verify Estimate`,
	UndoEstimateVerification = `${CerbosResource.Quote}:Undo Estimate Verification`,
	AddManualEstimate = `${CerbosResource.Quote}:Add manual Estimate`,
	ViewSimilarParts = `${CerbosResource.Quote}:View Similar Parts`,
	ViewSuggestedSuppliers = `${CerbosResource.Quote}:Show Suggested Suppliers`
}

export enum AdminPanelActions {
	Access = `${CerbosResource.AdminPanel}:Access Admin Panel`
}

export const isQuoteOwner = (request: CerbosValidationRequest) =>
	(request.principal.attr.ownership.owns ?? []).includes(request.resource.attr.quoteId);
export const isQuoteContributor = (request: CerbosValidationRequest) =>
	(request.principal.attr.ownership.contributes ?? []).includes(request.resource.attr.quoteId);

export const AllRoles = [
	CerbosRole.GCM,
	CerbosRole.MCET,
	CerbosRole.SCP,
	CerbosRole.Engineer,
	CerbosRole.SystemAdmin,
	CerbosRole.BusinessAdmin,
	CerbosRole.BU
] as const;

export const UserRolesAbbrMap = {
	[CerbosRole.GCM]: 'GCM',
	[CerbosRole.MCET]: 'MCET',
	[CerbosRole.SCP]: 'SCP',
	[CerbosRole.Engineer]: 'ENG',
	[CerbosRole.SystemAdmin]: 'SA',
	[CerbosRole.BusinessAdmin]: 'BA',
	[CerbosRole.BU]: 'BU'
};

export const ActionToResourceMap = {
	[CerbosResource.AdminPanel]: [AdminPanelActions.Access]
} as Record<string, string[]>;

export function findResourceByAction(action: string): CerbosResource | undefined {
	const resource = Object.keys(ActionToResourceMap).find(resource => ActionToResourceMap[resource].includes(action));
	return resource as CerbosResource;
}
