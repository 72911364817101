import { Injectable, computed, signal } from '@angular/core';
import { AppSecurityUser, LocalOwnerShipUpdatePayload, OwnershipKey } from './app-security.service';
import { CerbosRole } from './cerbos/cerbos.constants';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private readonly config = signal({} as any, {});

	private readonly ownershipUpdates = signal([] as LocalOwnerShipUpdatePayload[]);

	public appSecurityUsersWithLocalUpdates = computed(() => {
		const users = this.config().appSecurityUsers;
		const ownershipUpdates = this.ownershipUpdates() ?? [];

		if (!ownershipUpdates.length) {
			return users;
		}

		const updated = users.map((user: AppSecurityUser) => {
			const localUpdatesForUser = ownershipUpdates.filter(u => u.userIds.includes(user.username));
			const newOwnership = localUpdatesForUser.reduce((ownership, update) => {
				const { quoteId, ownershipKey } = update as LocalOwnerShipUpdatePayload;

				return {
					...ownership,
					...(ownershipKey === 'owns' && { owns: ownership.owns.concat(quoteId) }),
					...(ownershipKey === 'contributes' && { contributes: ownership.contributes.concat(quoteId) })
				};
			}, user.ownership);

			return {
				...user,
				ownership: newOwnership
			};
		});

		return updated;
	});

	public currentUser = computed(() => {
		const cognitoUser = this.config().cognitoUser;
		const matchedUser = this.appSecurityUsersWithLocalUpdates().find(
			(user: AppSecurityUser) => user!.username === cognitoUser?.username
		) as AppSecurityUser;

		const fakeRole = localStorage.getItem('mi6_role');

		if (fakeRole) {
			matchedUser.role = JSON.parse(fakeRole);
			matchedUser.roles = [JSON.parse(fakeRole)];
		}

		return matchedUser ?? null;
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	update(value: any) {
		const newConfig = { ...this.config(), ...value };

		this.config.set(newConfig);
	}

	updateOwnershipLocally(quoteId: string, userIds: string[], ownershipKey: OwnershipKey) {
		const newValue = this.ownershipUpdates().concat({
			quoteId,
			userIds,
			ownershipKey
		});

		this.ownershipUpdates.set(newValue);
	}

	getAppSecurityUsersAsIdToFullNameMap() {
		return this.appSecurityUsersWithLocalUpdates().reduce(
			(acc: Record<string, string>, user: AppSecurityUser) => {
				acc[user.username] = `${user.firstname} ${user.lastname}`;

				return acc;
			},
			{} as Record<string, string>
		);
	}

	getMcetUsersIds() {
		return (this.appSecurityUsersWithLocalUpdates() ?? []).filter(
			(user: AppSecurityUser) => user.roles.includes(CerbosRole.MCET) && this.currentUser().username !== user.username
		);
	}
}
