import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { JblGqlServerService } from '@jbl-pip/core-state';
import { Apollo, ApolloBase } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

@Injectable({
	providedIn: 'root'
})
export class GqlServerService extends JblGqlServerService {
	public main: ApolloBase;

	private mainEndpoint = 'gql';

	constructor(apollo: Apollo, httpLink: HttpLink) {
		super(apollo, httpLink);

		this.createPrimary(apollo, httpLink, this.mainEndpoint, environment.endpoints.apiUrl);
		this.main = apollo.use(this.mainEndpoint);
	}
}
